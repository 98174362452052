<template>
    <div class="library">
         <div class="filter horizFlex wrap " v-on:keyup.enter="filterlibrary" >
             <p class="filterlabel">Lookup</p>
             
             <select name="" id="" class='filterselect' v-model="libraryFilter.locationId" required @change="selectLocation">
                 <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                     {{loc.name}}
                 </option>
             </select>
             <select name="" id="" class='filterselect' v-model="libraryFilter.groupId" required >
                 <option v-for="loc in productGroupList" :key="loc.id" :value="loc.id" >
                     {{loc.name}}
                 </option>
             </select>
             <select name="" id="" class='filterselect' v-model="libraryFilter.libraryItemStatusId" required >
                 <option v-for="loc in libraryItemStatusList" :key="loc.ID" :value="loc.ID" >
                     {{loc.Name}}
                 </option>
             </select>
             <input class="filterinput" v-model="libraryFilter.studentCode" placeholder="Student ID/Name"/>
             <!-- <select name="" id="" class='filterselect' v-model="libraryFilter.studentId" required >
                 <option v-for="loc in studentList" :key="loc.id" :value="loc.id" >
                     {{loc.name}}
                 </option>
             </select>  -->
             
             <smallbutton toplabel="Go" @click="filterlibrary" buttonclass="buttonprimary narrowbutton"/>
             <smallbutton toplabel="Reset" buttonclass="buttonprimary narrowbutton" @click="resetfilter"/>
             
             <p class="responsemessage margin10" v-if="responseMessage!=null">{{ responseMessage }}</p>
             
         </div>
 
         <div class="margin10" v-if="pleaseWait">
                 <label class="pleasewait">Please Wait...</label>
         </div>
      
         <div class="horizFlexStretch">
            <div></div>
             <div class="horizFlexStretch" v-if="!showChecks" >
                
                 <addlibrary buttonclass="buttonprimary invbutton" @refresh="filterlibrary(true)"/>
                 <smallbutton toplabel="Return Items" buttonclass="buttonprimary invbutton" @click="showReturn=true"/>
                 <smallbutton toplabel="Update Due Date" buttonclass="buttonprimary invbutton" @click="showDue=true;newDueDate=null"/>
                 <confirm :showButton="paramTrue" :isAlert="paramTrue" :body="chargewarning" title="Charge Late Fee" @yes="chargelate()"  
                        buttonclass=" buttonprimary invbutton" :disabled="numToCharge==0"/>
             </div>
             <div class="horizFlexStretch" v-if="showReturn">
                    <div></div>
                    <div class="horizFlex">
                        <smallbutton toplabel="Save Returns" buttonclass="buttonprimary invbutton" @click="saveReturns"/>
                        <smallbutton toplabel="Cancel" buttonclass="buttonprimary invbutton" @click="showReturn=false"/>
                    </div>
                    
             </div>

             <div class="horizFlexStretch" v-if="showDue">
                    <div></div>
                    <div class="horizFlex">
                        <label>New Due Date</label>
                        <input type="date" v-model="newDueDate"/>
                        <smallbutton toplabel="Save Due Date" buttonclass="buttonprimary invbutton" @click="saveDueDate"/>
                        <smallbutton toplabel="Cancel" buttonclass="buttonprimary invbutton" @click="showDue=false"/>
                    </div>
                    
             </div>
         </div>
   
         
         <div >
             <div class="libheader" >
                 <linkbutton class="invrecordlabel  leftjustified bold" @click="setsort('student')" toplabel="Student"/>
                 <linkbutton class="invrecordlabel  leftjustified bold" @click="setsort('product')" toplabel="Product"/>
                 
                 <linkbutton class="invrecordlabel  leftjustified bold" @click="setsort('status')" toplabel="Status"/>
                 <linkbutton class="invrecordlabel bold " @click="setsort('checkedout')" toplabel="Checked Out Date"/>
                 <linkbutton class="invrecordlabel bold " @click="setsort('due')" toplabel="Due Date"/>
                 <linkbutton class="invrecordlabel bold " @click="setsort('returned')" toplabel="Returned Date"/>
                 <linkbutton class="invrecordlabel bold " @click="setsort('charged')" toplabel="Charged Date"/>
                 <linkbutton class="invrecordlabel bold " @click="setsort('amount')" toplabel="Charged Amount"/>
                 <div>
                    <p class="invrecordlabel bold " v-if="showChecks">Select</p>  
                </div>
             </div>
             <div class="vertScrollinv">
                 <div v-for="g in sortedList" :key="g.ID" :value="g.ID" :class="recordClass(g)">
 
                     <p class="invrecordlabel leftjustified">{{g.studentName}}</p>
                     <p class="invrecordlabel leftjustified">{{g.productName }}</p>
                    
                     <p :class="statusclass(g)">{{g.libraryItemStatusName}}</p>
                     <p class="invrecordlabel">{{formatDate(g.checkedOutDate)}}</p>
                     <p class="invrecordlabel">{{formatDate(g.dueDate)}}</p>
                     <p class="invrecordlabel">{{formatDate(g.returnedDate)}}</p>
                     <p class="invrecordlabel">{{formatDate(g.chargedDate)}}</p>
                     <p class="invrecordlabel ">{{formatcharged(g)}}</p>
                     <div >
                        <p class="invrecordlabel" v-if="!showChecks"></p>
                        <input type="checkbox" @change="setSelected(g.libraryItemId)" class="invrecordlabel approveCheck" v-if="oktoselect(g)" />
                    </div>
                 </div>
             </div>
             
         </div>
 
     </div>
   </template>
   
   <script>
   import {mapFields} from 'vuex-map-fields'
   import datamethods from '../../services/datamethods.js'
   import datamethodsreport from '../../services/datamethods-report.js'
   import format from '../../format.js'
   import smallbutton from '../controls/button.vue'
   import confirm from '../actions/confirm.vue'
 import linkbutton from '../controls/linkbutton.vue'
 import addlibrary from '../library/addlibrary.vue'
 import updatelibrary from '../library/updatelibrary.vue'
 import securityhelper from '../../services/securityhelper.js'

   export default {
       name:'library',
       mixins:[datamethods,datamethodsreport,format,securityhelper],
       components:{
         smallbutton,
         linkbutton,
         addlibrary,
         confirm,
         updatelibrary
       },
       computed:{
           ...mapFields([
     
               'pleaseWait',
               'pleaseWaitText',
               'libraryFilter',
               'locationList',
               'loggedInUser',
               'reportImageBasePath'
     
           ]),
          sortedList(){
             if(this.sortBy=='default'){return this.libraryList}
 
             var sort1 = this.sortaccending ? -1:1
             var sort2 = this.sortaccending ? 1:-1
 
 
             switch (this.sortBy) {
                 case 'student':
                     function compare(a,b){
                         if (a.studentName < b.studentName)
                             return sort1
                         if(a.studentName > b.studentName)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(compare)
                     break;
                 case 'product':
                     function compareb(a,b){
                         if (a.productName < b.productName)
                             return sort1
                         if(a.productName > b.productName)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(compareb)
                     break;
                 case 'quantity':
                     function comparec(a,b){
                         if (a.quantity < b.quantity)
                             return sort1
                         if(a.quantity > b.quantity)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(comparec)
                     break;    
                 case 'status':
                     function compared(a,b){
                         if (a.libraryItemStatusName < b.libraryItemStatusName)
                             return sort1
                         if(a.libraryItemStatusName > b.libraryItemStatusName)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(compared)
                     break;   
                 
                 case 'checkedout':
                     function comparee(a,b){
                         if (a.checkedOutDate < b.checkedOutDate)
                             return sort1
                         if(a.checkedOutDate > b.checkedOutDate)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(comparee)
                     break;
            
                
                 case 'due':
                     function comparef(a,b){
                         if (a.dueDate < b.dueDate)
                             return sort1
                         if(a.dueDate > b.dueDate)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(comparef)
                     break; 
                 case 'returned':
                     function compareg(a,b){
                         if (a.returnedDate < b.returnedDate)
                             return sort1
                         if(a.returnedDate > b.returnedDate)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(compareg)
                     break;
                case 'charged':
                     function compareh(a,b){
                         if (a.chargedDate < b.chargedDate)
                             return sort1
                         if(a.chargedDate > b.chargedDate)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(compareh)
                     break; 
                case 'amount':
                     function comparei(a,b){
                        var amta = a.chargeAmount
                        var amtb=b.chargeAmount
                        if(a.chargedDate.includes('0001-01-01')){amta=0}
                        if(b.chargedDate.includes('0001-01-01')){amtb=0}
                         if (amta < amtb)
                             return sort1
                         if(amta > amtb)
                             return sort2
                         return 0
                     }
                     return this.libraryList.sort(comparei)
                     break; 
                 default:
                     return this.libraryList
                     break;
             } 
          },
         showChecks(){
            return this.showDue || this.showReturn
         },
         hasChecks(){
            var checks = false
            this.libraryList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.selected){   
                        checks=true
                    } 
                }
                return checks
         },
         numToCharge(){
            var count = 0
            this.libraryList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                let inst=arr[index]
                if(inst.libraryItemStatusName=='Late' &&  inst.chargedDate.includes('001-01-01')){   
                    count = count + 1
                } 
            }
            return count
         },
         chargewarning(){
            return 'Do you want to charge the accounts of these ' + this.numToCharge + ' students that are LATE to return their items?'
         }
          
 
       },
      
       data(){
           return{
            responseMessage:null,     
            libraryList:[],
             
                 paramTrue:true,
                 sortBy:'default',
                 sortaccending:true,
                 libraryItemStatusList:[],
                 productGroupList:[],
                 studentList:[],

                 showReturn:false,
                 showDue:false,
               
                 newDueDate:null,
                 chargewarning_default:'Do you want to charge the accounts of all of these students that are LATE to return their items?',
                 
                 chargeconfirm:null
 
           }
       },
       methods:{
        isCharged(g){
            var charged = !String(g.chargedDate).includes('0001-01-01')
           // console.log('charged ' + g.chargedDate + ' ' + charged)
            return charged
        },
        statusclass(g){
            if(g.libraryItemStatusName=='Late' ){
                return "invrecordlabel  leftjustified redlabel"
            }else if(g.libraryItemStatusName=='Returned'){
                return "invrecordlabel  leftjustified greenlabel"
            }else{return "invrecordlabel  leftjustified"}
        },
        oktoselect(g){
            if(!this.showChecks) return false
            if(g.libraryItemStatusName=="Returned" && this.showReturn){return false}
            if(g.libraryItemStatusName=="Returned" && this.showDue){return false}
            if(!g.chargedDate.includes('001-01-01') && this.showDue){return false}
            return true
        },
        formatDate(dt){
            if(dt.includes('0001-01-01')){return null}
            return this.$options.filters.formatDateShort(dt)
        },
         addItem(){

         },
         setSelected(id){
              //  console.log('appid ' + id)
                this.libraryList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.libraryItemId ==id){   
                        inst.selected=!inst.selected
                       // console.log('set post ' + inst.ID + ' ' + inst.toApprove)
                    } 
                }
                
                
            },
         async saveReturns(){
            let req = {
                libraryItemList:this.libraryList,
                employeeId:this.loggedInUser.user_personId,
        
                
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'returnlibraryitems')
            //handle response
            if(response.Successful){
                this.responseMessage = response.StringResponse
                this.showReturn=false
                this.filterlibrary()
                this.pleaseWait=false;
            }else{
                this.responseMessage = response.Message
            }
        },
        async chargelate(){
            /* if(this.numToCharge==0){
                this.responseMessage="There are no late, uncharged students in these results"
                return
            } */
            this.chargewarning = 
            this.chargeconfirm='You have charged ' + this.numToCharge + ' student account(s).'
            let req = {
                libraryItemList:this.libraryList,
                employeeId:this.loggedInUser.user_personId,
        
                
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'chargelatelibraryitems')
            //handle response
            if(response.Successful){
                //response.StringResponse
                this.responseMessage = this.chargeconfirm
                this.filterlibrary()
                this.pleaseWait=false;
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false
        },
        async saveDueDate(){
            if(this.hasChecks==false){
                this.responseMessage="Please select at least one student"
                return
            }
            if(this.newDueDate==null){
                this.responseMessage="Please enter a new due date"
                return
            }
            let req = {
                libraryItemList:this.libraryList,
                employeeId:this.loggedInUser.user_personId,
                dueDate:this.newDueDate
                
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'updateduedate')
            //handle response
            if(response.Successful){
                this.responseMessage = response.StringResponse
                this.showDue=false
                this.filterlibrary()
                this.pleaseWait=false;
            }else{
                this.responseMessage = response.Message
            }
        },
        formatcharged(g){
            if(g.chargeAmount==null){return null}
            if(!this.isCharged(g)){return null}
             return g.chargeAmount.toFixed(2)
         },
         selectLocation(){
            this.GetStatus()
             this.GetGroups();
             this.GetStudents();
            this.filterlibrary()
         },
         setsort(s){
             if(s==this.sortBy){this.sortaccending=!this.sortaccending}
             else{
                 this.sortBy=s
             }
         },
         recordClass(r){
             return "librecord"
          },
         resetfilter(){
             this.libraryFilter={
                 libraryItemStatusId:-1,
                 groupId:-1, 
                 studentId:-1,
                 studentCode:null,
                 locationId:this.loggedInUser.LocationId,
                 filterString:null
             }
             this.filterlibrary()
         } ,
         async filterlibrary(resetmsg=false){
            if(resetmsg)(this.responseMessage=null)
             let req = {
                   LibraryFilter:this.libraryFilter
               }
               this.$store.dispatch('beforeAPI');
               var response = await this.callAPI(req,'libraryitemlist')
               //handle response
               if(response.Successful){
                    this.libraryList =response.LibraryItemList;
   
                    this.sortBy='default'
               }else{
                   this.responseMessage = response.Message
               }
               this.pleaseWait=false;  
         },
         async GetGroups(){
             let req = {
                   locationId:this.libraryFilter.locationId
             }
             this.$store.dispatch('beforeAPI');
             var response = await this.callAPI(req,'productgrouplibrary')
 
             if(response.Successful){
                 this.productGroupList=response.ProductGroupList
                 var all = {id:-1, name:'--Any Groups--'}
                 this.productGroupList.unshift(all)
             }else{
                 this.responseMessage = response.Message
             }
             this.pleaseWait=false;
         },
         async GetStatus(){
             let req = {
                   locationId:this.libraryFilter.locationId
             }
             this.$store.dispatch('beforeAPI');
             var response = await this.callAPI(req,'librarystatus')
 
             if(response.Successful){
                 this.libraryItemStatusList=response.ValueList
                 var all = {ID:-1, Name:'--Any Status--'}
                 this.libraryItemStatusList.unshift(all)
             }else{
                 this.responseMessage = response.Message
             }
             this.pleaseWait=false;
         },
         async GetStudents(){
             /* let req = {
                   locationId:this.libraryFilter.locationId
             }
             this.$store.dispatch('beforeAPI');
             var response = await this.callAPI(req,'productline')
 
             if(response.Successful){
                 this.libraryItemStatusList=response.ProductLineList
             
             }else{
                 this.responseMessage = response.Message
             }
             this.pleaseWait=false; */
         },
         async sync(){
            let req = {
                libraryItem:{libraryItemId:null},
         
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'synclibraryitem')
            //handle response
            if(response.Successful){
           
                this.pleaseWait=false;
            }else{
                this.responseMessage = response.Message
            }
        },
        
         
     },
     mounted(){
         
         this.libraryFilter.locationId=this.loggedInUser.LocationId
         this.sync()
             this.selectLocation()
     }
   }
   </script>
   
   <style>
   .library{
     height:75vh;
   }
  .libheader{
    
  
     margin:5px;
     border-radius: 2px;
     padding:0px 0px 0px 5px;
     display:grid;
     grid-template-columns: 3fr 3fr  2fr 2fr 2fr 2fr 2fr 2fr 2fr;
  }
  .librecord{
     
     background-color:var(--frame-color);
     margin:5px;
     border-radius: 2px;
     padding:0px 0px 0px 5px;
     display:grid;
     grid-template-columns: 3fr 3fr  2fr 2fr 2fr 2fr 2fr 2fr 2fr;
  }
  .vertScrollinv{
     overflow-x:hidden;
     overflow-y:scroll;
     width:100%;
     height:530px;
  }
  .filterinput{
     height:29px;
     margin-top:5px;
     margin-left:5px;
     font-size:inherit;
  }
  .invrecordlabel{
    margin:10px 10px 10px 10px;
    min-width:25px;
  }
  .invbutton{
     border: none;
 border-radius:5px;
 width:120px;
 height:40px;
 padding:0px;
 font-size: medium;
 margin:5px;
 text-decoration: none;
 color:var(--light-text-color);
  }
  .greenlabel{
    color:green;
  }
   </style>